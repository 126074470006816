import {
  Banner,
  Box,
  Container,
  IconAlert,
  Layout,
  Link,
  PreStyledComponents,
  Row,
  Theme,
  routesObject
} from "@life-without-barriers/react-components"
import {
  ContentfulPageReferences,
  ContentfulPageResources,
  Event
} from "../../../../contentTypes"

import { DateTime } from "luxon"
import { Location } from "@life-without-barriers/gatsby-common/src/contentTypes"
import { MSTContactForm } from "@life-without-barriers/shared-contact-form"
import React from "react"
import { Site } from "@life-without-barriers/gatsby-common"
import WrappedHelmet from "../../../../components/WrappedHelmet"
import { graphql } from "gatsby"
import queryString from "query-string"

const { IconWrapper, StandardButton } = PreStyledComponents
const { youthFull } = Theme

const isBrowser = typeof window !== undefined

type PageReferences = ContentfulPageReferences & Event

interface Props {
  location: Location
  data: {
    site: Site
    pageResources: {
      edges: [
        {
          node: ContentfulPageResources
        }
      ]
    }
  }
}

const validateEventDetails = (
  location: Location,
  allEvents: PageReferences[]
) => {
  if (!isBrowser) {
    return
  }

  const { eventId } = queryString.parse(location.search || "")
  if (!eventId) {
    return
  }
  const event = allEvents.find((e) => e.eventId === eventId)
  if (!event) {
    return {
      eventId: "abc-123",
      name: "cheese",
      description: "test mst registration",
      location: "the internet",
      buttonLabel: "register",
      buttonUrl: null,
      startDateTime: DateTime.utc().plus({ days: 200 }),
      endDateTime: DateTime.utc().plus({ days: 205 })
    }
  }

  return event
}

const InvalidEventMessage = () => {
  return (
    <div className="tc center pv4 ph4">
      <div className="flex items-center justify-center">
        <IconWrapper color={youthFull.xxxlight}>
          <IconAlert height="20" width="20" color={youthFull.xdark} />
        </IconWrapper>
        <div className="t-invalid-event pl3 fw7">Invalid Event</div>
      </div>

      <div className="mt4">
        <Link className="no-underline" to="/mst-training">
          <StandardButton shade="xdark" className="w-100 center">
            Return to training
          </StandardButton>
        </Link>
      </div>
    </div>
  )
}

const Register = ({
  location,
  data: {
    site: { siteMetadata },
    pageResources
  }
}: Props) => {
  const allEvents = pageResources.edges.map(({ node: { pageReferences } }) => {
    return pageReferences.filter(
      (resource) => resource.type === "ContentfulEvent"
    )
  })[0]
  const selectedEvent = validateEventDetails(location, allEvents)

  const breadcrumbItems = [
    routesObject.home,
    routesObject.service,
    {
      to: "/services/child-youth-and-family/",
      text: "Child, youth and family",
      title: "Return to parent page"
    },
    {
      to: "/services/child-youth-and-family/mst-training/",
      text: "Multisystemic Therapy Training (MST)",
      title: "Return to mst training"
    }
  ]

  return (
    <div>
      <WrappedHelmet
        title={`MST Training Registration | ${siteMetadata.title}`}
        description="MST is a family-focused and scientifically proven intervention program to help at-risk youth."
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
      />
      <Layout theme={youthFull}>
        <Banner
          title="MST Training Registration"
          centered
          breadcrumbs={breadcrumbItems}
        />
        <Container>
          <Row className="center">
            <Box className="w-100 w-80-l center copy">
              <div className="pa3 pa4-ns br2 lwb-shadow overflow-hidden bg-white">
                {selectedEvent ? (
                  <MSTContactForm selectedEvent={selectedEvent} />
                ) : (
                  <InvalidEventMessage />
                )}
              </div>
            </Box>
          </Row>
        </Container>
      </Layout>
    </div>
  )
}

export default Register

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    pageResources: allContentfulPageResources(
      filter: { title: { eq: "mst-training" } }
    ) {
      edges {
        node {
          title
          pageReferences {
            type: __typename
            eventId: contentful_id
            name
            description {
              childMarkdownRemark {
                html
              }
            }
            location {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
            startDateTime
            endDateTime
          }
        }
      }
    }
  }
`
